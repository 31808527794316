<template>
  <v-app t-data="app-bg" class="bg" :style="backgroundImage">
    <v-dialog-confirm :show="isConfirm" :email="email" :resend="resend"/>
    <v-main>
        <v-container class="fill-height">
           <img t-data="hospital-logo" :src="hospitalLogo" width="180" class="logo" />
           <v-row align="center" justify="center">
           <v-card flat>
                <v-card-text t-data="info-label">
                        <h2 class="text-center white--text">
                            Forgot your password?
                        </h2>
                        <div style="marginTop:2%">
                            <p class="text-center white--text">
                            Don’t worry. Resetting your password is easy, just tell us the email address you registered with portal.
                        </p>
                        </div>
                  <v-form ref="form" lazy-validation>
                    <v-text-field
                        t-data="info-input"
                        id="email"
                        v-model="email"
                        :error="isError"
                        :error-messages="msgError"
                        type="text"
                        placeholder="Your Email"
                        :rules="emailRules"
                        outlined
                      />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <!-- <a href="#">Forget password?</a> -->
                  <v-btn  @click="submit" large class="btn-login" t-data="send-btn">Send</v-btn>
                 
                </v-card-actions>
                 <v-btn @click="backToLogin" text large style="marginLeft:39%" t-data="back-btn"><span class="white--text">Back to Login</span></v-btn>
              </v-card>
        </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions} from 'vuex'
import Confirm from '../../components/AlertForgetPass'
import {imageHospitalLogo, imageHospitalBg} from '@/constants/constants'

export default {
  components: {
      'v-dialog-confirm': Confirm,
    },

  computed: {
    backgroundImage() {
      return {
        "background-image": `url(${imageHospitalBg})`
      }
    },
    hospitalLogo() {
      return imageHospitalLogo
    },
  },
  methods: {
     async submit (){
        if(this.$refs.form.validate()){
           let status = await this.forget(this.email)
           if(status){
             this.isConfirm = true;
             this.isError = false
             this.msgError = ''
             return
           }
           this.isError = true
           this.msgError = 'Not found your email address, please try again'
        }
    },
    forget(){
      this.$router.push({name: 'forget-passw'})
    },
    resend(){
      this.isConfirm = false;
      this.submit()
    },
    backToLogin() {
      this.$router.push({name: 'login'})
    },
    ...mapActions('auth', ['forget'])
  },
  data() {
    return {
     isConfirm:false,
     isLoad:true,
     isError:false,
     msgError:'',
     email:'',
     emailRules: [
        v => {
            this.isError = false,
            this.msgError = ''
            if(v != ''){
              return true
            }
            return 'E-mail is required'
          },
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) ||
          'E-mail must be valid'
      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-card {
  background-color: transparent;
}
.bg {
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: right top;
}
.logo {
  position: absolute;
  top: 103px;
  left: 55px;
}
form {
  & > label {
    color: white;
    font-size: 15px;
  }
}
.v-card__actions {
  a {
    color: white;
  }
  padding: 16px;
}
.v-input__slot {
  background: none;
  background-color: white !important;
}
.btn-login {
  width: 40%;
  background-color: var(--login-btn-color) !important;
  border-color: var(--login-btn-color) !important;
  color: white;
  margin-left:30%;
}
</style>

<style>
.v-input__slot {
  background: none;
  background-color: white !important;
}
</style>
