<template>
  <v-dialog v-model="show" persistent max-width="600">
    <v-card>
      <v-card-title t-data="headline">
        <span class="blue--text">Please check your email</span>
      </v-card-title>
     <v-card-text t-data="card-text">
      <div class="text--primary">
        We have sent a reset password email to <span class="blue--text">{{email}}</span><br>Please click the reset password link to set your new password
      </div>
      <div class="text--primary" style="marginTop:4%">
        Didn't received email yet?<br>
        Please check your spam folder, or <v-btn id="alert-resend" text @click="resend" class="blue--text" t-data="resend-btn">resend</v-btn> the email
      </div>
      <div class="text--primary" style="marginTop:4%">
        if you can remember your password ,<v-btn id="alert-back-to-login"  text @click="back" class="blue--text" t-data="back-btn">Back to login</v-btn>
      </div>
    </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Forget',
  props: {
    show: {type: Boolean},
    email:{type:String},
    resend: {type: Function},
    back: {
      required: false,
      default () {
        return () => this.goToLogin();
      },
    }
  },
  methods: {
    closePopup() {
      this.close()
    },
    goToLogin() {
      this.$router.push({name: 'login'})
    }
  }
}
</script>


<style scoped>
</style>

